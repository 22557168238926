import { Component, Injector, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { combineLatest, zip } from 'rxjs';
import { AppRoutes } from '@core/enums/routes.enum';
import { TranslateService } from '@ngx-translate/core';
import { DxpBaseListComponent } from '@core/base/dxp-base-list.class';
import {
  BaseDataApiModel,
  BaseDataIndexedDbModel,
  BaseDataValueService,
  LocaleApiModel,
  LocaleService,
} from '@capturum/complete';
import { EntityConfig } from '@core/models/entity-config.model';
import { Entity } from '@core/enums/entity.enum';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { ActionButton, ButtonType, LayoutConfig } from '@shared/services/layout-config.service';
import { DxpIndexableModuleService } from '@core/indexDb/services/dxp-indexable-module.service';
import { DxpIndexableBaseDataService } from '@core/indexDb/services/dxp-indexable-base-data.service';
import { LazyLoadEvent } from 'primeng/api';
import { get } from 'lodash';
import { FilterMatchMode, FilterType, TableAction, TableColumn, TableFilter } from '@capturum/ui/api';

interface BaseData extends BaseDataIndexedDbModel {
  label: string;
  value: string | number;
}

@Component({
  selector: 'app-base-data-list',
  templateUrl: 'base-data-list.component.html',
  styleUrls: ['base-data-list.component.scss'],
})
export class BaseDataListComponent extends DxpBaseListComponent<BaseDataApiModel> implements OnInit {
  public baseDataList: BaseData[];
  public currentBaseData: BaseData;
  public locales: LocaleApiModel[];
  public defaultFilters: TableFilter;
  public baseListActions: TableAction[] = [];
  public entityConfig: EntityConfig = {
    name: Entity.baseData,
  };

  public constructor(
    public injector: Injector,
    public apiService: BaseDataValueService,
    public translateService: TranslateService,
    public notificationService: NotificationService,
    public layoutUtilsService: LayoutUtilsService,
    private localeService: LocaleService,
    private dxpIndexableBaseDataService: DxpIndexableBaseDataService,
    private moduleService: DxpIndexableModuleService,
  ) {
    super(injector, translateService, notificationService, layoutUtilsService);

    this.includes = ['translations'];

    this.defaultFilters = {
      base_data_key_id: {
        value: 'nothing',
        matchMode: FilterMatchMode.EQUALS,
      },
    };

    this.loadTableDataCallback = (data) => {
      const adjustedItems = data.map((item) => {
        const transformedTranslations = {};

        item.translations.forEach((element) => {
          transformedTranslations[element.locale_id] = element.translation;
        });

        item.translations = transformedTranslations;

        if (item.parent_id) {
          item.parent = this.translateService.instant(`base-data.${item.parent_id}`);
        }

        return item;
      });

      this.defaultLoadTableCallBackLogic(adjustedItems);
    };
  }

  public ngOnInit(): void {
    this.getBaseDataKeys();
  }

  public onRowClick(id: string): Promise<boolean> {
    return this.router.navigateByUrl(this.getUrl(id));
  }

  public onFilterChange(event: TableFilter): void {
    let id = 'nothing';

    if (event?.base_data_key_id?.value) {
      id = event.base_data_key_id.value;
    }

    this.getCurrentBaseDataWasChanged(id);
    this.onFilter(event as any);
    this.applyFilter();
    this.layoutConfigService.addConfig(this.createConfig());
  }

  public loadTableData(event?: LazyLoadEvent): void {
    this.filters = { ...this.defaultFilters, ...this.filters };
    super.loadTableData(event);
  }

  protected overwriteDefaultConfig(entity: BaseDataApiModel, defaultConfig: LayoutConfig): LayoutConfig {
    const title = combineLatest([
      this.translateService.stream('dxp.sidebar.manage'),
      this.translateService.stream('config.base_data.title'),
    ]).pipe(
      map(([manage, observation]) => {
        return `${manage} - ${observation}`;
      }),
    );

    let actionButtons: ActionButton[] = [];

    this.baseListActions = [];

    if (this.currentBaseData?.editable_by_tenant) {
      actionButtons = [
        {
          type: ButtonType.ADD,
          label: 'button.add',
          icon: 'fas fa-plus',
          route: this.getUrl(),
        },
      ];

      this.baseListActions.push({
        ...this.generateDeleteAction,
        permissions: [],
      });
    }

    return {
      ...defaultConfig,
      title,
      subtitle: this.translateService.stream('dxp.subtitle.overview'),
      actionButtons,
    };
  }

  private getColumns(): TableColumn[] {
    return [
      {
        // This column is just for displaying custom filter
        field: 'base_data_key_id',
        header: this.translateService.stream('config.base_data.title'),
        filterable: true,
        filterType: FilterType.SELECT,
        filterOptions: this.baseDataList,
        filterMatchMode: FilterMatchMode.EQUALS,
        filterSearchable: true,
        filterIconClass: 'fas fa-database',
        styleClass: 'd-none',
      },
      {
        field: 'value',
        header: this.translateService.stream('base-data-form.value'),
        filterable: !!this.currentBaseData,
        filterIconClass: 'fas fa-search',
        filterPlaceholder: this.translateService.stream('dxp.base-data.search'),
      },
      {
        field: 'parent',
        header: this.translateService.stream('base-data-form.parent'),
        filterable: false,
      },
    ];
  }

  private getUrl(id?: string): string {
    return `/${AppRoutes.admin}/${AppRoutes.baseData}/${this.currentBaseData.id}/${id || AppRoutes.add}/${
      this.currentBaseData.key
    }`;
  }

  private getBaseDataKeys(): void {
    zip(this.dxpIndexableBaseDataService.loadBaseData(), this.moduleService.loadModules()).subscribe(
      ([baseDataItems, modules]) => {
        this.baseDataList = baseDataItems
          .map((baseData: BaseData) => {
            const module = modules.find((moduleModel) => {
              return moduleModel.id === baseData.module_id;
            });

            if (module) {
              baseData.key = baseData.key?.replace(/_/g, '-');
              baseData.label = this.translateService.instant(`${module.name}.base-data.${baseData.key}`);
              baseData.value = baseData.id;
            }

            return module ? baseData : null;
          })
          .filter(Boolean);

        const id = get(this.lazyLoadEvent, 'filters.base_data_key_id.value');

        if (id) {
          this.getCurrentBaseDataWasChanged(id);
        }
        this.columns = TableColumn.getColumns(this.getColumns());
        this.layoutConfigService.addConfig(this.createConfig());
      },
    );
  }

  private getCurrentBaseDataWasChanged(id: string): void {
    this.currentBaseData = this.baseDataList.find((baseData) => {
      return baseData.id === id;
    });

    this.layoutConfigService.addConfig(this.createConfig());
    this.addColumnsForEachLocale();
  }

  private addColumnsForEachLocale(): void {
    if (!this.locales) {
      this.localeService.index().subscribe(({ data }) => {
        this.locales = data;
        this.setLocales();
      });
    }
  }

  private setLocales(): void {
    const defaultColumns: TableColumn[] = this.getColumns();

    if (this.locales) {
      this.locales.forEach((locale) => {
        const column = {
          field: `translations.${locale.id}`,
          header: locale.name,
          filterable: false,
          sortable: false,
        };

        defaultColumns.push(column);
      });
    }

    this.columns = TableColumn.getColumns(defaultColumns);
  }
}
