import { Injectable } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import { CompleteConfig, IndexedDbGuard, IndexedDbService, TranslationApiModel } from '@capturum/complete';
import { Observable } from 'rxjs';
import { NavigationService } from '@core/services/navigation.service';
import { tap } from 'rxjs/operators';
import { IndexableDataService } from '@core/indexDb/services/dxp-indexable-data.service';
import { CacheService } from '@core/services/cache.service';

@Injectable({
  providedIn: 'root',
})
export class DxpIndexableModuleService extends IndexableDataService<TranslationApiModel> {
  protected endpoint = 'role/module';
  protected outdatedEntityKey = 'modules';

  constructor(
    protected readonly api: ApiHttpService,
    protected readonly translateService: TranslateService,
    protected readonly authService: AuthService,
    protected readonly cacheService: CacheService,
    protected readonly navigationService: NavigationService,
    protected readonly completeConfig: CompleteConfig,
    protected readonly indexedDbService: IndexedDbService,
    protected readonly indexedDbGuard: IndexedDbGuard,
  ) {
    super(api, translateService, authService, completeConfig, indexedDbService, indexedDbGuard);
  }

  /**
   * I would deprecate this method, but @Emendis/complete components rely on it
   */
  public loadModules(): Observable<any> {
    return this.getData();
  }

  public onFatalError(): Observable<void> {
    return super.onFatalError().pipe(
      tap(() => {
        this.cacheService.forceLogout();
      }),
    );
  }
}
